import http from '../http-client';
import { FilterOptions } from '../types';
import { CreateDepositVaResponse } from './deposit';

export type GetBillingListResponse = {
  result: boolean;
  msg: string;
  total: number;
  data: Array<{
    biaya_admin: string;
    deposit_terpakai: string;
    hrg_lelang: string;
    id: number;
    id_bidder: number;
    id_lelang: string;
    id_nipl: number;
    km: string;
    kode_nipl: string;
    lokasi_lelang: string;
    merk: string;
    nama_lelang: string;
    no_lot: string;
    no_pol: string;
    no_tagihan: string;
    no_va: string | null;
    status: number | null;
    pembayaran: string;
    denda: string;
    tagihan: string;
    tahun: string;
    tgl_lelang: null | string;
    type: string;
  }>;
};

export type GetPaymentHistoryResponse = {
  result: boolean;
  msg: string;
  total: number;
  data: Array<{
    id: number;
    lokasi: string;
    nama_lelang: string;
    tgl_lelang: string;
    nipl: string | null;
    biaya_admin: string | null;
    admin_fee?: string;
    harga_kendaraan: string | null;
    deposit: string | null;
    tgl_bayar: string | null;
    pembayaran: string | null;
    jenis: 'DEPOSIT' | 'PAYMENT';
  }>;
};

export type GetTotalPaymentResponse = {
  result: boolean;
  msg: string;
  data: {
    item: Array<{
      id: number;
      no_lot: string;
      kendaraan: string;
      tagihan: number;
    }>;
    tagihan: number;
    deposit: number[];
    deposit_terpakai: number;
    total_bayar: number;
    diskon: number;
    lelang_id: string;
    metode: Array<{
      id: number;
      name: string;
    }>;
    kendaraan: string;
    lelang_unit_id: string;
    deposit_terpakai_value: string;
    tagihan_value: string;
  };
};

export type GetTotalPaymentVAResponse = {
  result: boolean;
  msg: string;
  data: {
    item: Array<{
      id: number;
      kendaraan: string;
      no_lot: string;
      tagihan: number;
    }>;
    tagihan: number;
    deposit: number[];
    deposit_terpakai: number;
    total_bayar: number;
    bank: Array<{
      bank_img: string;
      bank_name: string;
      bank_code: string;
      bank_text: string;
      bank_fee: number;
    }>;
  };
};

export type PayBankTransferResponse = {
  result: boolean;
  msg: string;
  data: {
    kendaraan: string;
    due_date_tagihan: string;
    item: Array<{
      id: number;
      no_lot: string;
      kendaraan: string;
      tagihan: number;
    }>;
    deposit_terpakai: number;
    nama_lelang: string;
    total_bayar: number;
  };
};

type PayData = {
  bank_code: string;
  param: 'PAYMENT';
  data: Omit<GetTotalPaymentVAResponse['data'], 'bank'>;
  type: 'MOBIL' | 'MOTOR';
};

type PayBankTransferData = {
  kendaraan: string;
  lelang_unit_id: string;
  deposit_terpakai_value: string;
  tagihan_value: string;
  tagihan: number;
  lelang_id: string;
  total_deposit_terpakai: number;
  total_bayar: number;
};

export type GetPaymentProofAuctionsResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    LELANG_ID: string;
    LELANG_NAMA: string;
  }>;
};

export type GetPaymentProofBillsResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    ID_TRACE: string;
    PAYMENT_NOMINAL: number;
  }>;
};

export type GetPaymentProofItemsResponse = {
  result: boolean;
  msg: string;
  data: {
    kendaraan: string;
    item: Array<{
      id: number;
      no_lot: string;
      kendaraan: string;
      tagihan: number;
    }>;
    lelang_unit_id: string;
    deposit_terpakai: number;
  };
};

export type SubmitPaymentProofData = {
  lelang?: string;
  tagihan?: string;
  deposit_terpakai?: string;
  rincian?: string;
  lelang_unit_id: string;
  jumlah_bayar: string;
  tanggal_pembayaran_1: string;
  bukti_upload_1: FileList[0];
  tanggal_pembayaran_2: string;
  bukti_upload_2: FileList[0];
  tanggal_pembayaran_3: string;
  bukti_upload_3: FileList[0];
  tanggal_pembayaran_4: string;
  bukti_upload_4: FileList[0];
  tanggal_pembayaran_5: string;
  bukti_upload_5: FileList[0];
};

export type SubmitPaymentProofResponse = {
  result: boolean;
  msg: string;
};

const getBillingList = async (token: string, options: FilterOptions): Promise<GetBillingListResponse> => {
  const response = await http.post('get-tagihan-all', options, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

const getPaymentHistory = async (token: string, options: FilterOptions): Promise<GetPaymentHistoryResponse> => {
  const response = await http.post('riwayat-bayar', options, { headers: { Authorization: `Bearer ${token}` } });

  return response.data;
};

const getTotalPayment = async (
  token: string,
  type: 'MOBIL' | 'MOTOR',
  data: string,
): Promise<GetTotalPaymentResponse> => {
  const response = await http.post(
    'payment/total-pelunasan',
    { type, data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const getTotalPaymentVA = async (
  token: string,
  type: 'MOBIL' | 'MOTOR',
  data: string,
): Promise<GetTotalPaymentVAResponse> => {
  const response = await http.post(
    'payment/total',
    { type, data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const pay = async (token: string, data: PayData): Promise<CreateDepositVaResponse> => {
  const response = await http.post(
    'midtrans/generate',
    { ...data, lelang_id: '-', nominal: data.data.total_bayar, data: JSON.stringify(data.data) },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const payBankTransfer = async (token: string, data: PayBankTransferData): Promise<PayBankTransferResponse> => {
  const response = await http.post(
    'payment/do-pelunasan-bankTransfer',
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const getPaymentProofAuctions = async (token: string): Promise<GetPaymentProofAuctionsResponse> => {
  const response = await http.get('payment/get-lelang-cid', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

const getPaymentProofBills = async (token: string, lelang_id: string): Promise<GetPaymentProofBillsResponse> => {
  const response = await http.post(
    'payment/get-tagihan-lid',
    {
      lelang_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const getPaymentProofItems = async (token: string, id_trace: string): Promise<GetPaymentProofItemsResponse> => {
  const response = await http.post(
    'payment/get-rincian-item',
    {
      id_trace,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const submitPaymentProof = async (token: string, data: SubmitPaymentProofData): Promise<SubmitPaymentProofResponse> => {
  const fd = new FormData();
  fd.append('lelang_unit_id', data.lelang_unit_id);
  fd.append('jumlah_bayar', data.jumlah_bayar);
  fd.append('tanggal_pembayaran_1', data.tanggal_pembayaran_1);
  fd.append('bukti_upload_1', data.bukti_upload_1 as File);
  fd.append('tanggal_pembayaran_2', data.tanggal_pembayaran_2);
  fd.append('bukti_upload_2', data.bukti_upload_2 as File);
  fd.append('tanggal_pembayaran_3', data.tanggal_pembayaran_3);
  fd.append('bukti_upload_3', data.bukti_upload_3 as File);
  fd.append('tanggal_pembayaran_4', data.tanggal_pembayaran_4);
  fd.append('bukti_upload_4', data.bukti_upload_4 as File);
  fd.append('tanggal_pembayaran_5', data.tanggal_pembayaran_5);
  fd.append('bukti_upload_5', data.bukti_upload_5 as File);
  const response = await http.post('payment/do-upload-bukti', fd, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export default {
  getBillingList,
  getPaymentHistory,
  getTotalPayment,
  getTotalPaymentVA,
  pay,
  payBankTransfer,
  getPaymentProofAuctions,
  getPaymentProofBills,
  getPaymentProofItems,
  submitPaymentProof,
};
