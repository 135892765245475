import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

const Login = React.lazy(() => import('pages/login/Login'));
const ForgotPassword = React.lazy(() => import('pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/resetPassword/ResetPassword'));
const Register = React.lazy(() => import('pages/register/Register'));
const RegisterSuccess = React.lazy(() => import('pages/register/RegisterSuccess'));
const Profile = React.lazy(() => import('pages/profile/personalData/PersonalData'));
const Billing = React.lazy(() => import('pages/profile/billing/Billing'));
const Payments = React.lazy(() => import('pages/profile/payments/Payments'));
const Deposit = React.lazy(() => import('pages/profile/deposit/Deposit'));
const PaymentHistory = React.lazy(() => import('pages/profile/paymentHistory/PaymentHistory'));
const Auction = React.lazy(() => import('pages/auction/auction/Auction'));
const ConductorFloor = React.lazy(() => import('pages/conductorFloor/ConductorFloor'));
const SelectionAuction = React.lazy(() => import('pages/auction/selectionAuction/SelectionAuction'));
const SelectionPayment = React.lazy(() => import('pages/auction/selectionPayment/SelectionPayment'));
const SelectionPaymentTwo = React.lazy(() => import('pages/auction/selectionPaymentTwo/SelectionPaymentTwo'));
const SelectionPaymentThree = React.lazy(() => import('pages/auction/selectionPaymentThree/SelectionPaymentThree'));
const SelectionPaymentFour = React.lazy(() => import('pages/auction/selectionPaymentFour/SelectionPaymentFour'));
const SelectionPaymentMethod = React.lazy(() => import('pages/auction/selectionPaymentMethod/SelectionPaymentMethod'));

const Page404 = React.lazy(() => import('pages/page404/Page404'));

const routes = [
  { path: '/login', exact: true, component: Login },
  { path: '/forgot-password', exact: true, component: ForgotPassword },
  { path: '/reset-password', exact: true, component: ResetPassword },
  { path: '/register', exact: true, component: Register },
  { path: '/register/success', exact: true, component: RegisterSuccess },
  { path: '/profile/personal-data', exact: true, component: Profile, private: true },
  { path: '/profile/billing', exact: true, component: Billing, private: true },
  { path: '/profile/payments', exact: true, component: Payments, private: true },
  { path: '/profile/deposit', exact: true, component: Deposit, private: true },
  { path: '/profile/payment-history', exact: true, component: PaymentHistory, private: true },
  { path: '/auction', exact: true, component: Auction, private: true },
  { path: '/auction/add', exact: true, component: SelectionAuction, private: true },
  { path: '/auction/payment', exact: true, component: SelectionPayment, private: true },
  { path: '/auction/payment/:id', exact: true, component: SelectionPaymentTwo, private: true },
  { path: '/auction/payment-three', exact: true, component: SelectionPaymentThree, private: true },
  { path: '/profile/payments/:type/:id', exact: true, component: SelectionPaymentMethod, private: true },
  { path: '/profile/payments-va/:type/:id', exact: true, component: SelectionPaymentFour, private: true },
  { path: '/conductor-floor', exact: true, component: ConductorFloor, private: true },
  { path: '/', exact: true, component: (): ReactElement => <Redirect to="/auction" /> },

  { path: '*', component: Page404 },
];

export default routes;
