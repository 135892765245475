import http from '../http-client';

export type GetMasterBaseResponse = {
  result: boolean;
  msg: string;
  data: Array<{ id: number; deskripsi: string }>;
};

export type GetMasterMetodeBayarResponse = {
  result: boolean;
  msg: string;
  data: Array<{ id: number; deskripsi: string; code: string; aktif: number }>;
};

export type GetMasterProvinsiResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    PROVINSI_ID: number;
    PROVINSI_NAMA: string;
  }>;
};

export type GetMasterKotaResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    KOTA_ID: number;
    PROVINSI_ID: number;
    KOTA_NAMA: string;
  }>;
};

export type GetMasterLokasiResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    lokasi_code: string;
    provinsi: string;
    kota: string;
    nama: string;
    no_rek: string;
    kode_inventori: string;
    kode_akunting: string;
    tipe: string;
    detail: string;
    status: string;
    date_created: string;
    date_modified: string;
    created_by: string;
    modified_by: string;
  }>;
};

export type GetMasterLelangResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    lelang_id: string;
    lokasi_code: string;
    nama: string;
    planned_start: string | null;
    executed_start: string | null;
    due_date: string;
    jenis_kendaraan: number;
    status: number;
    nominal_deposit: number;
  }>;
};

const getMasterPekerjaan = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-pekerjaan');
  return response.data;
};

const getMasterKendaraanDicari = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-kendaraan-dicari');
  return response.data;
};

const getMasterBank = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-bank');
  return response.data;
};

const getMasterJenisMobilisasi = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-jenis-mobilisasi');
  return response.data;
};

const getMasterPilihan = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-pilihan');
  return response.data;
};

const getMasterMetodeBayar = async (): Promise<GetMasterMetodeBayarResponse> => {
  const response = await http.get('mst-metode-bayar');
  return response.data;
};

const getMasterSumberDana = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-sumber-dana');
  return response.data;
};

const getMasterTujuanBeli = async (): Promise<GetMasterBaseResponse> => {
  const response = await http.get('mst-tujuan-beli');
  return response.data;
};

const getMasterProvinsi = async (): Promise<GetMasterProvinsiResponse> => {
  const response = await http.get('mst-provinsi');
  return response.data;
};

const getMasterKota = async (): Promise<GetMasterKotaResponse> => {
  const response = await http.get('mst-kota');
  return response.data;
};

const getMasterLokasi = async (): Promise<GetMasterLokasiResponse> => {
  const response = await http.get('mst-lokasi');
  return response.data;
};

const getMasterLelang = async (): Promise<GetMasterLelangResponse> => {
  const response = await http.get('mst-lelang');
  return response.data;
};

export default {
  getMasterPekerjaan,
  getMasterTujuanBeli,
  getMasterSumberDana,
  getMasterMetodeBayar,
  getMasterPilihan,
  getMasterJenisMobilisasi,
  getMasterBank,
  getMasterKendaraanDicari,
  getMasterKota,
  getMasterProvinsi,
  getMasterLokasi,
  getMasterLelang,
};
