import http from '../http-client';
import { FilterOptions } from '../types';

export type GetDepositListResponse = {
  result: boolean;
  msg: string;
  total: number;
  data: Array<{
    bank: null | string;
    cabang: null | string;
    id: number;
    notification?: string;
    id_bidder: null | number;
    id_lelang: string;
    jml_nipl: string;
    nipl_detail: null | string;
    nipl_tidak_terpakai: null | string;
    lokasi_lelang: string;
    nama_lelang: string;
    nama_rek: null | string;
    no_rek: null | string;
    payment_va: null | string;
    admin_fee: string;
    nominal: string;
    status: number;
    terpakai: string;
    expired_at: null | string;
    tgl_lelang: string;
    tgl_return: string;
    tgl_submit: string;
    type: number;
  }>;
};

export type SubmitPaymentProofData = {
  deposit_id: number;
  metode_bayar: number;
  bank: string;
  no_rek: string;
  tgl_bayar: string;
  status: number;
  nama_rek: string;
  bukti_upload: FileList[0];
};

export type SubmitPaymentProofResponse = {
  result: boolean;
  msg: string;
};

export type CreateDepositData = {
  lelang_id: string;
  jml_unit: number;
  nominal: number;
};

export type CreateDepositResponse = {
  result: boolean;
  msg: string;
};

export type GetVaBankResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    bank_img: string;
    bank_name: string;
    bank_code: string;
    bank_text: string;
    bank_fee: number;
  }>;
};

export type CreateDepositVaData = {
  lelang_id: string;
  unit: number;
  bank_code: string;
  nominal: number;
  param: 'DEPOSIT';
};

export type CreateDepositVaResponse = {
  result: boolean;
  msg: string;
  data: {
    total: string;
    admin_fee: string;
    company_code: null | string;
    va_code: string;
    pdf_url: null | string;
    expired_at: string;
  };
};

const getDepositList = async (token: string, options: FilterOptions): Promise<GetDepositListResponse> => {
  const response = await http.post('get-deposit-detail', options, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

const getAuctionDepositList = async (token: string): Promise<GetDepositListResponse> => {
  const response = await http.get('get-deposit-details', { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

const submitPaymentProof = async (token: string, data: SubmitPaymentProofData): Promise<SubmitPaymentProofResponse> => {
  const fd = new FormData();
  Object.entries(data).forEach((entry) => {
    if (!entry[1]) return;
    fd.append(entry[0], typeof entry[1] === 'number' ? entry[1].toString() : entry[1]);
  });
  const response = await http.post('submit-deposit', fd, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

const createDeposit = async (token: string, data: CreateDepositData): Promise<CreateDepositResponse> => {
  const response = await http.post('create-deposit', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

const getVaBank = async (token: string): Promise<GetVaBankResponse> => {
  const response = await http.get('midtrans/bank', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

const createDepositVa = async (token: string, data: CreateDepositVaData): Promise<CreateDepositVaResponse> => {
  const response = await http.post('midtrans/generate', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export default {
  getDepositList,
  getAuctionDepositList,
  submitPaymentProof,
  createDeposit,
  createDepositVa,
  getVaBank,
};
