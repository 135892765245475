import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAsync from '@alifaishol/use-async';
import { LoginResponse } from 'services/api/resources/auth';
import { AxiosError } from 'axios';
import api from '../services/api';

type AuthContextType = {
  currentUser?: LoginResponse;
  loginChecked: boolean;
  login: (email: string, password: string) => void;
  handleAuthError: (err: Error | AxiosError) => void;
  logout: () => void;
  loading: boolean;
  error?: Error;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => {
  const [loginChecked, setLoginChecked] = useState<boolean>(false);
  const {
    exec,
    state: { loading, error, data },
    setData,
  } = useAsync(api.auth.login, {
    onSuccess: (d) => {
      window.localStorage.setItem('currentUser', JSON.stringify(d));
    },
  });

  const login = useCallback(
    (email, password) => {
      exec(email, password);
    },
    [exec],
  );

  const logout = useCallback(() => {
    window.localStorage.removeItem('currentUser');
    setData(undefined);
  }, [setData]);

  const handleAuthError = useCallback(
    (err: Error | AxiosError) => {
      if ('response' in err && err.response?.status === 401) {
        logout();
      }
    },
    [logout],
  );

  useEffect(() => {
    try {
      const dataString = window.localStorage.getItem('currentUser');
      if (!dataString) throw Error();
      const currentUser = JSON.parse(dataString);
      setData(currentUser);
      setLoginChecked(true);
    } catch (err) {
      setData(undefined);
      setLoginChecked(true);
    }
  }, [setData]);

  return (
    <AuthContext.Provider value={{ loginChecked, currentUser: data, login, logout, loading, error, handleAuthError }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const ctx = useContext(AuthContext);
  if (ctx === undefined) {
    throw Error('useAuthContext can only be used within AuthProvider');
  }
  return ctx;
};
