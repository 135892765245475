import React, { FC, ReactNode, useContext, createContext, useEffect } from 'react';
import {
  GetMasterBaseResponse,
  GetMasterProvinsiResponse,
  GetMasterKotaResponse,
  GetMasterMetodeBayarResponse,
  GetMasterLokasiResponse,
  GetMasterLelangResponse,
} from 'services/api/resources/master-data';
import api from 'services/api';
import useAsync from '@alifaishol/use-async';

type MasterDataContextType = {
  data?: {
    pekerjaan: GetMasterBaseResponse['data'];
    kendaraanDicari: GetMasterBaseResponse['data'];
    pilihan: GetMasterBaseResponse['data'];
    tujuanBeli: GetMasterBaseResponse['data'];
    provinsi: GetMasterProvinsiResponse['data'];
    kota: GetMasterKotaResponse['data'];
    bank: GetMasterBaseResponse['data'];
    metodeBayar: GetMasterMetodeBayarResponse['data'];
    sumberDana: GetMasterBaseResponse['data'];
    lokasi: GetMasterLokasiResponse['data'];
    lelang: GetMasterLelangResponse['data'];
  };
  sync: () => Promise<void>;
  error?: Error;
  loading: boolean;
};

const getData = async (): Promise<MasterDataContextType['data']> => {
  const responses = await Promise.all<
    GetMasterBaseResponse,
    GetMasterBaseResponse,
    GetMasterBaseResponse,
    GetMasterBaseResponse,
    GetMasterProvinsiResponse,
    GetMasterKotaResponse,
    GetMasterBaseResponse,
    GetMasterMetodeBayarResponse,
    GetMasterBaseResponse,
    GetMasterLokasiResponse
  >([
    api.masterData.getMasterPekerjaan(),
    api.masterData.getMasterKendaraanDicari(),
    api.masterData.getMasterPilihan(),
    api.masterData.getMasterTujuanBeli(),
    api.masterData.getMasterProvinsi(),
    api.masterData.getMasterKota(),
    api.masterData.getMasterBank(),
    api.masterData.getMasterMetodeBayar(),
    api.masterData.getMasterSumberDana(),
    api.masterData.getMasterLokasi(),
  ]);

  const lelangResponse = await api.masterData.getMasterLelang();

  return {
    pekerjaan: responses[0].data,
    kendaraanDicari: responses[1].data,
    pilihan: responses[2].data,
    tujuanBeli: responses[3].data,
    provinsi: responses[4].data,
    kota: responses[5].data,
    bank: responses[6].data,
    metodeBayar: responses[7].data,
    sumberDana: responses[8].data,
    lokasi: responses[9].data,
    lelang: lelangResponse.data,
  };
};

const MasterDataContext = createContext<MasterDataContextType | undefined>(undefined);

export const MasterDataProvider: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
  const {
    exec,
    state: { loading, error, data },
  } = useAsync(getData);

  useEffect(() => {
    exec();
  }, [exec]);

  return (
    <MasterDataContext.Provider value={{ sync: exec, loading, error, data }}>{children}</MasterDataContext.Provider>
  );
};

export const useMasterData = (): MasterDataContextType => {
  const ctx = useContext(MasterDataContext);
  if (ctx === undefined) {
    throw Error('useMasterData can only be used within MasterDataProvider');
  }
  return ctx;
};
