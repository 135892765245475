/* eslint-disable no-param-reassign */
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
    'auksi-key': 'API_AUCTION',
  },
});

client.interceptors.response.use(
  (res) => {
    if (res.data.result === false && res.data.msg) {
      return Promise.reject(Error(res.data.msg));
    }
    return res;
  },
  (error) => {
    if (error.response?.data?.msg) {
      error.message = error.response.data.msg;
      if (error.response.data.msg === 'Unkown Customer') {
        error.response.status = 401;
      }
    }
    if (error.response?.data?.rcdesc) {
      error.message = error.response.data.rcdesc;
    }
    return Promise.reject(error);
  },
);

export default client;
