/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { HashRouter, Route, Switch, Redirect, RouteProps } from 'react-router-dom';

import { useAuthContext, AuthProvider } from 'contexts/AuthContext';
import { MasterDataProvider } from 'contexts/MasterDataContext';
import Spinner from 'components/common/spinner/Spinner';

// import { NotifBadgeProvider } from 'contexts/NotifBadgeContext';
import routes from './routes';

import 'sweetalert2/dist/sweetalert2.css';
import 'typeface-poppins';
import 'assets/styles/react-modern-calendar-datepicker.css';
import 'assets/styles/tailwind.output.css';

const FullscreenLoading = (): ReactElement => (
  <div className="w-screen h-screen flex justify-center items-center">
    <Spinner className="text-blue-400 w-7 h-7" />
  </div>
);

const App = (): React.ReactElement => (
  <AuthProvider>
    <MasterDataProvider>
      <HashRouter>
        <React.Suspense fallback={<FullscreenLoading />}>
          <Switch>
            {routes.map((route) => {
              return (
                route.component &&
                (route.private ? (
                  <PrivateRoute path={route.path} exact={route.exact}>
                    <route.component />
                  </PrivateRoute>
                ) : (
                  <Route path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))
              );
            })}
          </Switch>
        </React.Suspense>
      </HashRouter>
    </MasterDataProvider>
  </AuthProvider>
);

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { currentUser, loginChecked } = useAuthContext();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!loginChecked) {
          return <FullscreenLoading />;
        }
        return currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default App;
