import http from '../http-client';

export type GetAuctionItemResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    nama_lelang: string;
    tgl_lelang: string;
    kode_nipl: string;
    no_pol: string;
    merk: string;
    type: string;
    tahun: string;
    hrg_terjual: string;
  }>;
};

export type GetActiveAuctionResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    id: number;
    type: 1 | 2;
    no_inventori: string;
    notification?: string;
    merk: string;
    no_pol: string;
    tahun: string;
    km: string;
    lane: string;
    no_lot: string;
    int_grade: string;
    eks_grade: string;
    mesin_grade: string;
    foto: string;
    hrg_awal: string;
    hrg_tertinggi: string;
    status: number;
    next_id: number;
    next_lot: string;
    next_no_pol: string;
    next_merk: string;
    countdown: string | null;
    posisi_countdown: string | null;
    tipe_countdown: number;
    kode_nipl?: string;
    nominal_kelipatan: number;
    nama_lokasi: string;
  }>;
};

export type BidData = {
  type: 'car' | 'bike';
  lelang_id: string;
  no_inventory: string;
  id_alokasi_detail: string;
  hrg_tawar: number;
};

export type BidResponse = {
  result: boolean;
  msg: string;
};

export type GetBidderResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    id: number;
    customer_no: string;
    lelang_id: string;
    tgl_bidder: string;
    status: number;
  }>;
};

export type BidLogData = {
  lelang_id: string;
  no_inventory: string;
  nipl: string;
};

export type BidLogResponse = {
  result: boolean;
  msg: string;
  data: Array<{
    deskripsi: string;
    harga: number;
    nipl: string;
  }>;
};

const getAuctionItem = async (token: string, idLelang: string): Promise<GetAuctionItemResponse> => {
  const response = await http.post(
    'get-item-rekap',
    { lelang_id: idLelang },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const getAuctionImg = async (token: string, type: string, filename: string): Promise<string> => {
  const response = await http.get(`get-image${type}?namefile=${filename}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
  });

  const blob = new Blob([response.data], {
    type: 'image/jpeg',
  });

  const objectURL = URL.createObjectURL(blob);

  return objectURL;
};

const getActiveAuction = async (token: string, auctionId: string): Promise<GetActiveAuctionResponse> => {
  const response = await http.post(
    'get-lelang-item',
    { lelang_id: auctionId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const bid = async (token: string, data: BidData): Promise<BidResponse> => {
  const getBidderRes = await http.post<GetBidderResponse>(
    'get-bidder',
    { lelang_id: data.lelang_id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const bidderIds = getBidderRes.data.data.map((item) => item.id);
  if (bidderIds.length < 1) throw Error('Bidder ID tidak tersedia!');
  const response = await http.post(
    `bidding-${data.type}`,
    {
      lelang_id: data.lelang_id,
      [`id_alokasi_${data.type}_detail`]: data.id_alokasi_detail,
      bidder_id: bidderIds[0],
      no_inventory: data.no_inventory,
      hrg_tawar: data.hrg_tawar,
      status: 1,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

const getBidLog = async (token: string, data: BidLogData): Promise<BidLogResponse> => {
  const response = await http.post(
    'midtrans/get-log-bid',
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export default {
  getAuctionItem,
  getAuctionImg,
  getActiveAuction,
  bid,
  getBidLog,
};
