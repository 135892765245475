import http from '../http-client';

export type LoginResponse = {
  result: boolean;
  msg: string;
  data: {
    customer_no?: string;
    customer_point?: string;
    alamat: string;
    alamat_perusahaan: string;
    bank: string;
    customer_ktp: string;
    email: string;
    expired_ktp: string;
    jenis_customer: string;
    kendaraan_dicari: string;
    kewarganegaraan: 'wni' | 'wna';
    kota: string;
    metode_bayar: string;
    nama_belakang: string;
    nama_depan: string;
    nama_rek: string;
    no_fax: string;
    no_ktp: string;
    no_rek: string;
    no_telp_perusahaan: string;
    no_telp_pribadi: string;
    npwp: string;
    pekerjaan: string;
    perusahaan: string;
    provinsi: string;
    sumber_dana: string;
    tempat_lahir: string;
    tgl_lahir: string;
    token: string;
    tujuan_beli: string;
  };
};

type GetLatestCustomerNoResponse = {
  result: boolean;
  msg: string;
  data: {
    customerNo: string;
  };
};

export type RegisterData = {
  nama_depan: string;
  nama_belakang?: string;
  tgl_lahir: string;
  tempat_lahir: string;
  no_ktp: string;
  expired_ktp?: string;
  kewarganegaraan: string;
  provinsi: string;
  kota: string;
  alamat: string;
  no_telp_pribadi: string;
  no_fax?: string;
  pekerjaan: string;
  perusahaan?: string;
  alamat_perusahaan?: string;
  no_telp_perusahaan?: string;
  jenis_customer: string;
  npwp?: string;
  kendaraan_dicari?: string;
  email: string;
  password: string;
  tujuan_beli?: string;
  no_rek: string;
  bank: string;
  nama_rek: string;
  sumber_dana: string;
  metode_bayar: string;
  customer_ktp: FileList[0];
};

type RegisterResponse = {
  result: boolean;
  msg: string;
  data: {
    customerNo: string;
  };
};

export type UpdateProfileData = Omit<RegisterData, 'customer_no'>;

type ForgotPasswordResponse = {
  result: boolean;
  msg: string;
};

export type ResetPasswordData = {
  otp: string;
  password: string;
  repassword: string;
};

type ResetPasswordResponse = {
  result: boolean;
  msg: string;
};

const login = async (email: string, password: string): Promise<LoginResponse> => {
  const response = await http.post('login', { email, password });
  return response.data;
};

const getLatestCustomerNo = async (): Promise<GetLatestCustomerNoResponse> => {
  const response = await http.get('get-latest-cs-no');
  return response.data;
};

const register = async (data: RegisterData): Promise<RegisterResponse> => {
  const custNoRes = await getLatestCustomerNo();
  const fd = new FormData();
  fd.append('customer_no', custNoRes.data.customerNo);
  Object.entries(data).forEach((entry) => {
    if (!entry[1]) return;
    fd.append(entry[0], entry[1]);
  });
  const response = await http.post('register', fd);
  return response.data;
};

const updateProfile = async (token: string, data: UpdateProfileData): Promise<RegisterResponse> => {
  const fd = new FormData();
  Object.entries(data).forEach((entry) => {
    if (!entry[1]) return;
    fd.append(entry[0], entry[1]);
  });

  const response = await http.post('update-profile', fd, { headers: { Authorization: `Bearer ${token}` } });

  return response.data;
};

const forgotPassword = async (email: string): Promise<ForgotPasswordResponse> => {
  const response = await http.post('forgot', { email });
  return response.data;
};

const resetPassword = async (data: ResetPasswordData): Promise<ResetPasswordResponse> => {
  const response = await http.post('reset', data);
  return response.data;
};

export default { login, getLatestCustomerNo, register, forgotPassword, updateProfile, resetPassword };
